import React, { useEffect, useRef, useState } from "react";
import './VendorProfile.css';
import { BiSolidPencil } from "react-icons/bi";
import axios from "axios";
import PuffLoader from "react-spinners/PuffLoader";
import VendorSideBar from "../Components/VendorSideBar";
import NavigationBar from "../Components/NavigationBar";
import { API_URL } from "../../config";

const VendorProfile = () => {
    const [isDisabled, setIsDisabled] = useState(true);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const user = JSON.parse(localStorage.getItem('VendorData'));
    const [loading, setLoading] = useState(true);
    const [showPan, setShowPan] = useState(false);
    const [showCheque, setShowCheque] = useState(false);
    const [showAgreement, setShowAgreement] = useState(false);
    const [gender, setGender] = useState();

    const [Vendor, setVendor] = useState();

    const getVendorData = async () => {
        await axios.get(`${API_URL}/admin/vendors-detail/${user._id}`)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setVendor(response.data)
                }
                console.log(response)
            })
            .catch((error) => { 
                alert("Unable to get Details")
            })
    }

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        try {
            const updatingUser = {
                _id: user._id,
                gender: gender
            };

            const response = await axios.put(`${API_URL}/vendor/updateUser`, updatingUser);
            if (response.status === 200) {
                alert(response.data.message);
                // Optionally navigate to a different page or refresh the data
            } else {
                alert("Update failed: " + response.data.message);
            }
        } catch (error) {
            alert("Something went wrong: " + error.response?.data?.message || error.message);
        }
    };

    const handleDownload = (file_URL, docName, fileType) => {
        const imageUrl = file_URL;
        const anchor = document.createElement("a");
        anchor.href = imageUrl;
        anchor.download = `${docName}.${fileType}`;;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    };

    useEffect(() => {
        getVendorData();
    }, [user])

    useEffect(() => {
        setTimeout(() => {
            setLoading(false)
        }, 3000)
    }, [])

    return (
        <>
            <div className='profilepage'>
                <NavigationBar />
                {
                    loading ?
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                            <PuffLoader size={28}
                                loading={loading} />
                        </div>
                        :
                        <div className='row'>
                            <VendorSideBar />
                            <div className='col-lg-10 col-md-10 col-sm-12 col-12'>
                                <form onSubmit={handleSubmit} style={{ marginBottom: "10%" }}>
                                    <div className='lists'>
                                        <div className='d-flex justify-content-between border-bottom border-primary'>
                                            <span className='vender-heading fw-semibold'>Account Details</span>
                                        </div>
                                        <div className="row userInfo mt-2">
                                            <div className="col-lg-6 col-md-6 mb-3">
                                                <label className="field_label mb-2 fw-semibold" htmlFor="email">Email Address</label>
                                                <input className="form-control" type="tel" value={Vendor.email} disabled />
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                            <label className="field_label mb-2 fw-semibold" htmlFor="phone">Mobile Number</label>
                                            <input className="form-control" type="tel" value={Vendor.phone} disabled />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-between border-bottom border-primary'>
                                            <span className='vender-heading fw-semibold'>Basic Details</span>
                                        </div>
                                        <div className="mt-3">
                                            <div className="row mx-2">
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">Company/Organiser Name</label>
                                                    <input className="form-control" type="text" value={Vendor.name} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">Registered Address</label>
                                                    <input className="form-control" type="text" value={Vendor.address} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">Full Name</label>
                                                    <input className="form-control" type="text" value={Vendor.userName} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">PAN card Number</label>
                                                    <div className="d-flex ">
                                                        <input className="form-control me-2" type="text" value={Vendor.pan_no} disabled />
                                                        <div className="d-flex align-items-center"><button className="view_doc_btn" data-bs-toggle="modal" data-bs-target="#documentModal" onClick={() => { setShowPan(true); setShowAgreement(false); setShowCheque(false) }}>view</button></div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="gender">Gender</label>
                                                    <input className="form-control" type="text" value={Vendor.Gender} onChange={(e)=>setGender(e.target.value)} />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">GSTIN Number</label>
                                                    {
                                                        Vendor.GSTIN_Details && Vendor.GSTIN_Details.map((GSTIN)=>(
                                                            <input className="form-control" type="text" value={GSTIN?.GSTIN_No} disabled />
                                                        ))
                                                        
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-3">
                                            <div className='d-flex justify-content-between border-bottom border-primary'>
                                                <span className='vender-heading fw-semibold'>Bank Details</span>
                                            </div>
                                            <div className="row mx-2">
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="bank_Account_type">Account Type</label>
                                                    <input className="form-control" type="text" value={Vendor.bank_Account_type} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="beneficiar_name">Beneficiary Name</label>
                                                    <input className="form-control" type="text" value={Vendor.beneficiar_name} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="bank_account_no">Account Number</label>
                                                    <input className="form-control" type="text" value={Vendor.bank_account_no} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="bank_name">Bank Name</label>
                                                    <input className="form-control" type="text" value={Vendor.bank_name} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="IFSC_code">IFSC Code</label>
                                                    <input className="form-control" type="text" value={Vendor.IFSC_code} disabled />
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">Cancelled Cheque</label>
                                                    <div className="mt-3"><button className="view_doc_btn" data-bs-toggle="modal" data-bs-target="#documentModal" onClick={() => { setShowPan(false); setShowAgreement(false); setShowCheque(true) }}>view</button></div>
                                                </div>
                                                <div className="col-lg-3 mb-3">
                                                    <label className="field_label mb-2 fw-semibold" htmlFor="phone">Agreement</label>
                                                    <div className="mt-3"><button className="view_doc_btn" data-bs-toggle="modal" data-bs-target="#documentModal" onClick={() => { setShowPan(false); setShowAgreement(true); setShowCheque() }}>view</button></div>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            Vendor.additionalBank && Vendor.additionalBank > 0 ?
                                                (<>
                                                    {
                                                        Vendor.additionalBank.map((item, index) => (
                                                            <div className="mt-3">
                                                                <div className='d-flex justify-content-between' style={{ marginTop: "14px" }}>
                                                                    <span className='vender-heading fw-semibold'>Additional Bank Details</span>
                                                                </div>
                                                                <div className="row mx-2">
                                                                    <div className="col-lg-3 mb-3">
                                                                        <label className="field_label mb-2 fw-semibold" htmlFor="bank_Account_type">Account Type</label>
                                                                        <input className="form-control" type="tel" value={item.bank_Account_type} disabled />
                                                                    </div>
                                                                    <div className="col-lg-3 mb-3">
                                                                        <label className="field_label mb-2 fw-semibold" htmlFor="beneficiar_name">Beneficiary Name</label>
                                                                        <input className="form-control" type="tel" value={item.beneficiar_name} disabled />
                                                                    </div>
                                                                    <div className="col-lg-3 mb-3">
                                                                        <label className="field_label mb-2 fw-semibold" htmlFor="bank_account_no">Account Number</label>
                                                                        <input className="form-control" type="tel" value={item.bank_account_no} disabled />
                                                                    </div>
                                                                    <div className="col-lg-3 mb-3">
                                                                        <label className="field_label mb-2 fw-semibold" htmlFor="bank_name">Bank Name</label>
                                                                        <input className="form-control" type="tel" value={item.bank_name} disabled />
                                                                    </div>
                                                                    <div className="col-lg-3 mb-3">
                                                                        <label className="field_label mb-2 fw-semibold" htmlFor="IFSC_code">IFSC Code</label>
                                                                        <input className="form-control" type="tel" value={item.IFSC_code} disabled />
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        )
                                                    }
                                                </>)
                                                :
                                                ''
                                        }
                                    </div>
                                    <button className="updatebtn mt-3" type="submit">Update</button>
                                </form>
                            </div>
                        </div>
                }
            </div>

            <div class="modal fade" id="documentModal" tabindex="-1" aria-labelledby="documentModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content w-75 m-auto">
                        <div class="modal-body">
                            <div className="text-center">
                                <p className={`sub-mat-head ${showPan ? "d-block" : "d-none"}`}>Pan Card</p>
                                <p className={`sub-mat-head ${showCheque ? "d-block" : "d-none"}`}>Cancel Cheque</p>
                                <p className={`sub-mat-head ${showAgreement ? "d-block" : "d-none"}`}>Agreement</p>
                            </div>
                            <div>
                                <div className={`${showPan ? "d-block" : "d-none"}`}>
                                    <img className="w-100 h-100" src={`${Vendor?.pan_card}`} alt='vendor_docs' />
                                </div>
                                <div className={`${showCheque ? "d-block" : "d-none"}`}>
                                    <img className="w-100 h-100" src={`${Vendor?.cancel_cheque}`} alt='vendor_docs' />
                                </div>
                                <div className={`${showAgreement ? "d-block" : "d-none"}`}>
                                    <img className="w-100 h-100" src={`${Vendor?.signed_Agreement}`} alt='vendor_docs' />
                                </div>
                            </div>
                        </div>
                        <div class=" w-50 m-auto my-2 text-center d-flex justify-content-between">
                            <button type="button" class="cancel-btn" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="update-btn" data-bs-dismiss="modal"
                                onClick={() => {
                                    if (showPan) {
                                        handleDownload(`${Vendor.pan_card}`, "Pan_card", "jpg");
                                    } else if (showCheque) {
                                        handleDownload(`${Vendor.cancel_cheque}`, "Cancel_cheque", "jpg");
                                    } else if (showAgreement) {
                                        handleDownload(`${Vendor.signed_Agreement}`, "Agreement", "pdf");
                                    }
                                }}>Download</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default VendorProfile;
