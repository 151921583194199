import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../config';
import { Edit2, EyeOff, Trash } from 'react-feather';
import { MdCurrencyRupee } from 'react-icons/md';
import SyncLoader from 'react-spinners/SyncLoader';
import { BsFillInfoCircleFill } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css'; // CSS for Bootstrap
import * as bootstrap from 'bootstrap'; // Import all of Bootstrap's JS



function TicketDetail({ onNext, eventDetail }) {
  const [isListShow, setIsListShow] = useState(false);
  const [ticket, setTicket] = useState({
    ticket_Name: '',
    ticket_type: 'paid',
    total_Quantity: 0,
    ticket_price: 0,
    sale_start: '',
    sale_start_Time: '',
    sale_end: '',
    sale_end_Time: '',
    min_booking: 0,
    max_booking: 0,
    ticket_description: '',
    isGst: "No",
    GST: 0,
    event_id: eventDetail._id,
    per_day: false,
    cover_price: 0,
    isTicket_for_date: true,
    ticket_for: '',
  });
  const [ticketList, setTicketList] = useState([]);
  const [isAllSession, setIsAllSession] = useState(false);
  // Form Validation 
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const modalRef = useRef(null); // Modal ref
  const validate = () => {
    const newErrors = {};
    if (!ticket.ticket_Name) {
      newErrors.ticket_Name = 'Ticket name is required';
    }
    if (!ticket.total_Quantity) {
      newErrors.total_Quantity = 'Quantity is required';
    }
    setErrors(newErrors);
    const isValidForm = Object.keys(newErrors).length === 0;
    setIsValid(isValidForm);
    return isValidForm;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    if (!validate()) {
      setLoading(false);
      return; // Prevent form submission if validation fails
    }

    try {
      const result = await axios.post(`${API_URL}/ticket/createticket`, ticket);

      if (result.status === 200) {
        const data = { ticket_id: result.data.ticketCreated._id, event_id: ticket.event_id }
        await axios.put(`${API_URL}/api/addeventticket`, data);
        getAllTickets();
        setIsListShow(true);

        // Reset ticket form after successful submission
        setTicket({
          ...ticket,
          ticket_Name: '',
          ticket_type: 'paid',
          total_Quantity: 0,
          ticket_price: 0,
          sale_start: '',
          sale_start_Time: '',
          sale_end: '',
          sale_end_Time: '',
          min_booking: 0,
          max_booking: 0,
          ticket_description: '',
          isGst: "No",
          GST: 0,
          event_id: eventDetail._id,
          isTicket_for_date: true,
        });
      } else {
        alert("Something went wrong");
      }
    } catch (error) {
      alert("Unable to add ticket");
    } finally {
      setLoading(false)
    }
  };

  // Function to Fetch and store all ticket list
  const getAllTickets = async () => {
    const ticketStore = await axios.get(`${API_URL}/ticket/gettickets/${ticket.event_id}`);
    setTicketList(ticketStore.data.all_tickets);
  }

  // Delete ticket functionality
  const handleDeleteTicket = async (ticket_id) => {
    await axios.delete(`${API_URL}/ticket/tickets/${ticket_id}`)
      .then((response) => {
        getAllTickets();
      }).catch((err) => alert("Unable to delete ticket"));

  };
  const handleChange = (tab) => {
    onNext(tab, isValid);
  }

  const showModal = () => {
    // Show modal when component mounts
    const modal = new bootstrap.Modal(modalRef.current);
    modal.show();

    modalRef.current.addEventListener('hidden.bs.modal', () => {
      // Remove modal backdrop and restore body scroll
      const backdrop = document.querySelector('.modal-backdrop');
      if (backdrop) {
        backdrop.remove(); // Removes the backdrop after modal is closed
      }

      // Ensure that body scroll is restored and modal-open class is removed
      document.body.classList.remove('modal-open');
      document.body.style.paddingRight = ''; // Clear the right padding added by Bootstrap
      // Restore body overflow behavior
      document.body.style.overflow = 'auto'; // Ensure scrolling is allowed
    });

    return () => {
      // Clean up event listener on unmount
      modalRef.current.removeEventListener('hidden.bs.modal', () => { });
    };
  }

  const handleDateSelection = async () => {
    console.log("Selected date: ", ticket.ticket_for);
    const updatesetIsAllSession = await axios.put(`${API_URL}/api/updatesetIsAllSession/${eventDetail._id}`, { isAllSession })
    const modal = bootstrap.Modal.getInstance(modalRef.current); // Get modal instance
    modal.hide(); // Hide the modal after date selection
  };

  const [dates, setDates] = useState([]);

  // Fetch dates in range when the component mounts or when event dates change
  useEffect(() => {
    const startDate = new Date(eventDetail.event_starts_date);
    const endDate = new Date(eventDetail.event_ends_date);
    const generatedDates = getDatesInRange(startDate, endDate);
    setDates(generatedDates);
    console.log("EVENT : ", eventDetail, "setDates", dates);
    showModal();
  }, [eventDetail.event_starts_date, eventDetail.event_ends_date]);


  const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate);
    const end = new Date(endDate);
    const datesArray = [];

    while (date <= end) {
      datesArray.push(new Date(date));
      date.setDate(date.getDate() + 1); // Move to the next day
    }

    console.log("Dates are :", datesArray)

    return datesArray;
  };

  return (
    <>
      <div>
        {/* Ticket Details Form */}
        <div className={`${!isListShow ? "d-block" : "d-none"}`}>
          <form onSubmit={handleSubmit}>
            <div className='addeventdetailDiv setbottommargin'>
              <div className="container">
                <div className='container'>
                  <div className='row' id="ticket-detail">
                    {/* ticket name */}
                    <div className='col-lg-6'>
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_Name'>Ticket Name</label> <span className='text-danger fs-4'>*</span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='text'
                          name='ticket_Name'
                          placeholder='Early Bird/RSVP/Regular Entry etc'
                          value={ticket.ticket_Name}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              ticket_Name: e.target.value
                            });
                          }}
                          required
                        />
                        {errors.ticket_Name && <p className='poppins16 text-danger ms-3'>{errors.ticket_Name}</p>}
                      </div>
                    </div>
                    {/* ticket type */}
                    <div className='col-lg-6'>
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_type'>Ticket Type</label> <span className='text-danger fs-4'> </span>
                        <div className='d-flex justify-content-between'>
                          <button type='button'
                            className={`ticket-type-btn ${ticket.ticket_type === "paid" ? "primary text-white" : "ticket-type-btn"}`}
                            onClick={() => { setTicket({ ...ticket, ticket_type: "paid" }) }} >
                            Paid
                          </button>
                          <button type='button'
                            className={`ticket-type-btn ${ticket.ticket_type === "free" ? "primary text-white" : "ticket-type-btn"}`}
                            onClick={() => { setTicket({ ...ticket, ticket_type: "free" }) }} >
                            Free
                          </button>
                          {/* <button type='button' className={`"btn tickettypebtn border-primary" ${ticket.ticket_type === "private" ? "btn primary text-white" : "btn border-primary"}`} onClick={() => { setTicket({ ...ticket, ticket_type: "private" }) }}> Private</button> */}
                        </div>
                      </div>
                    </div>
                    {/* ticket description */}
                    <div className='col-lg-6'>
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_description'>Ticket Description</label> <span className='text-danger fs-4'></span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='text'
                          name='ticket_description'
                          placeholder='Cover/No-cover/Entire Only etc'
                          value={ticket.ticket_description}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              ticket_description: e.target.value
                            });
                          }} />
                      </div>
                    </div>
                    {/* ticket price */}
                    <div className={`col-lg-6 ${ticket.ticket_type === "paid" || ticket.ticket_type === "private" ? "d-block" : "d-none"}`}>
                      <div className={`text-start mb-3`}>
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_price'>Ticket Price</label> <span className='text-danger fs-4'>*</span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          name='ticket_price'
                          placeholder='Enter Ticket Price in INR'
                          value={ticket.ticket_price === 0 ? '' : ticket.ticket_price}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              ticket_price: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* ticket quantity */}
                    <div className='col-lg-6'>
                      <div className="text-start mb-3">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='total_Quantity'>Total Quantity</label> <span className='text-danger fs-4'>*</span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          name='total_Quantity'
                          placeholder='Enter Total Quantity'
                          value={ticket.ticket_type === "free" ? 250 : ticket.total_Quantity}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              total_Quantity: ticket.ticket_type === "free" ? 250 : e.target.value,
                            });
                          }}
                          // disabled={ticket.ticket_type === "free"}
                          required
                        />
                        {errors.total_Quantity && <p className='text-danger ms-3'>{errors.total_Quantity}</p>}
                      </div>
                    </div>
                    <div className={`col-lg-12 mb-3 ${eventDetail.event_starts_date === eventDetail.event_ends_date || isAllSession ? 'd-none' : 'd-block'}`}>
                      <input
                        type='checkbox'
                        name='per_day'
                        checked={ticket.per_day}  // Bind the checkbox's checked state to ticket.per_day
                        onChange={(e) => setTicket({ ...ticket, per_day: e.target.checked })} // Set per_day based on checked state
                      />
                      <span> Ticket for per day</span> <span className="infoicon"> <BsFillInfoCircleFill className="text-info" /> 
                      <span className="ticketperday_info">
                          Tickets are sold daily. e.g. if ticket quantity = 10, 10 ticket/day
                        </span>
                      </span>

                      {/* <span><BsFillInfoCircleFill className='text-info infoicon' /></span> 
                        <span className='ticketperday_info'> Tickets be sold daily. e.g. if the tic.quantity = 10, 10 tic. sold/day</span> */}

                    </div>
                    {/* cover price */}
                    <div className={`col-lg-6 ${ticket.ticket_type === "paid" || ticket.ticket_type === "private" ? "d-block" : "d-none"}`}>
                      <div className="mb-3 text-start">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='max_booking'>Cover Price</label> <span className='text-danger fs-4'> </span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          name='cover_price'
                          placeholder='Enter cover price'
                          value={ticket.cover_price}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              cover_price: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>
                    {/* sale start from */}
                    <div className='col-lg-6'>
                      <div className="text-start">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_start'>Ticket sale Starts from</label>
                        <div className='d-flex px-1'>
                          <input className='form-control me-3' style={{ borderColor: "#2C9CF0" }}
                            type='date'
                            name='sale_start'
                            value={ticket.sale_start}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                              setTicket({
                                ...ticket,
                                sale_start: e.target.value
                              });
                            }}
                          />
                          <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                            type='time'
                            name='sale_start_Time'
                            value={ticket.sale_start_Time}
                            onChange={(e) => {
                              setTicket({
                                ...ticket,
                                sale_start_Time: e.target.value
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* sale end at */}
                    <div className='col-lg-6'>
                      <div className="mb-3 text-start">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_end'>Ticket Sale Ends at</label>
                        <div className='d-flex px-1 mb-2'>
                          <input className='form-control me-3' style={{ borderColor: "#2C9CF0" }}
                            type='date'
                            name='sale_end'
                            value={ticket.sale_end}
                            min={ticket.sale_start || new Date().toISOString().split('T')[0]}
                            onChange={(e) => {
                              setTicket({
                                ...ticket,
                                sale_end: e.target.value
                              });
                            }}
                          />
                          <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                            type='time'
                            name='sale_end_Time'
                            value={ticket.sale_end_Time}
                            onChange={(e) => {
                              setTicket({
                                ...ticket,
                                sale_end_Time: e.target.value
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    {/* min booking  */}
                    <div className='col-lg-6'>
                      <div className="mb-3 text-start">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='min_booking'>Minimum per Booking</label>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          name='min_booking'
                          placeholder='Enter minimum booking quantity'
                          value={ticket.min_booking === 0 ? '' : ticket.min_booking}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              min_booking: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>

                    {/* max booking */}
                    <div className='col-lg-6'>
                      <div className="mb-3 text-start">
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='max_booking'>Maximum per Booking</label>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='number'
                          onWheel={(e) => e.target.blur()}
                          name='max_booking'
                          placeholder='Enter maximum booking quantity'
                          value={ticket.max_booking === 0 ? '' : ticket.max_booking}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              max_booking: e.target.value
                            });
                          }}
                        />
                      </div>
                    </div>

                    {/* Ticket for Particular date
                    <div className='col-lg-6'>
                      <input
                        type='checkbox'
                        name='isTicket_for_date'
                        checked={ticket.isTicket_for_date}  // Bind the checkbox's checked state to ticket.isTicket_for_date
                        onChange={(e) => setTicket({ ...ticket, isTicket_for_date: e.target.checked })} // Set per_day based on checked state
                      />
                      <span> Ticket for particular Day</span> <span><BsFillInfoCircleFill className='text-info' /></span>
                      <div className={`mb-3 ${!ticket.isTicket_for_date ? 'd-none' : 'd-block'}`}>
                        <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_for'>Date for this Ticket :</label> <span className='text-danger fs-4'></span>
                        <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                          type='date'
                          name='ticket_for'
                          value={ticket.ticket_for}
                          onChange={(e) => {
                            setTicket({
                              ...ticket,
                              ticket_for: e.target.value
                            });
                          }}
                        />
                      </div>

                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className='ticketfooterDiv d-flex justify-content-center py-3'>
              <button type='submit' className='btn createticketbtn'>
                {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Create Ticket"}
              </button>
            </div>
          </form>
        </div>
        <div className={`${isListShow ? "d-block" : "d-none"}`}>
          {/* Added Ticket List */}
          <div className={`addeventdetailDiv mt-4`} id="largesScreenDevice">
            {
              ticketList.map((tickets) => {
                return (
                  <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "90%" }}
                    key={tickets._id}>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-12 text-start d-flex flex-column' >
                      <span className='fw-bold'>{tickets.ticket_Name}</span>
                      <span style={{ fontSize: "small" }}>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                    </div>
                    <div className='col-lg-2'>
                      <p>{tickets.total_Quantity} Ticket(s)</p>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-3'><MdCurrencyRupee /> {tickets.ticket_price}</div>
                    {/* <div className='col-lg-1 col-md-1 col-sm-1 col-3'><Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} /></div> */}
                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                      {/* <Edit2 size={17} /> */}
                    </div>
                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'><Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} /></div>
                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                      {/* <EyeOff size={17} /> */}
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                  </div>
                )
              })
            }
            <div className='text-center'>
              <button
                className="btn addticketbtn my-3 fw-bold"
                onClick={() => { setIsListShow(false) }}>+ Add Another Ticket for same date</button>
              {
                !isAllSession ?
                  <button
                    className="btn addticketbtn my-3 fw-bold"
                    onClick={() => { setIsListShow(false); showModal() }}>+ Add Ticket for different date</button>
                  : ""
              }

            </div>
            {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}
          </div>
          <div className={`addeventdetailDiv mt-4`} id="SmallScreenDevice">
            {
              ticketList.map((tickets) => {
                return (
                  <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "99%" }}
                    key={tickets._id}>
                    <div className='col-6 text-start d-flex flex-column mb-2'>
                      <span className='fw-bold poppins16-sm'>{tickets.ticket_Name}</span>
                      <span className='poppins10-sm'>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                    </div>
                    <div className='col-3 mb-2'>
                      <p className='poppins14-sm'>{tickets.total_Quantity} Ticket(s)</p>
                    </div>
                    <div className='col-3 mb-2'>
                      <p className='poppins14-sm'><MdCurrencyRupee /> {tickets.ticket_price}</p>
                    </div>
                    <div className='col-6'>
                      <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                    </div>
                    <div className='col-6 d-flex justify-content-center'>
                      <Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} />
                      {/* <Edit2 size={17} /> */}
                      {/* <EyeOff size={17} /> */}
                    </div>
                  </div>
                )
              })
            }
            <div className='text-center'>
              <button
                className="btn addticketbtn my-3 fw-bold"
                onClick={() => { setIsListShow(false) }}>+ Add Another Ticket for same date</button>
              {
                !isAllSession ?
                  <button
                    className="btn addticketbtn my-3 fw-bold"
                    onClick={() => { setIsListShow(false); showModal() }}>+ Add Ticket for different date</button> : ""
              }

            </div>
            {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}
          </div>
          <div className='adminfooterDiv'>
            {/* <button type='button'
            className={`btn btn-secondary me-5 px-5 `}
            onClick={()=>handleChange("add-details")}
          >Back</button> */}
            <button type='button' className='btn basicnextbtn' onClick={() => handleChange("add-on")}>Next</button>
          </div>
        </div>
      </div>


      {/* Modal to select ticket date */}
      <div
        className="modal fade"
        id="selectTicketDate"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="selectTicketDateLabel"
        aria-hidden="true"
        ref={modalRef}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="p-1 ps-5">
              <p className="date-title" id="selectTicketDateLabel">
                Select Date
              </p>
            </div>
            <div className="modal-body">
              <div className='px-5'>
                <div className='row sm-row'>
                  {dates
                    // .filter((date) => {
                    //   const dateString = date.toISOString().split('T')[0];

                    //   // return event.ticket.some(ticket =>
                    //   //     ticket.isTicketForDay 
                    //   //         ? ticket.ticket_for_Date === dateString 
                    //   //         : true // Include all dates if isTicketForDay is false
                    //   // );
                    // })
                    .map((date, index) => {
                      return (
                        <div className='sm-custom col-lg-4 col-md-4 col-sm-6 col-6 mb-3' key={index}>
                          <p
                            className={`dateSpanStyle text-center ${ticket.ticket_for === date ? 'selectedDate' : ''}`}
                            style={{ pointerEvents: isAllSession ? 'none' : 'auto', opacity: isAllSession ? 0.5 : 1 }}
                            onClick={() => {
                              if (!isAllSession) {
                                setTicket({ ...ticket, ticket_for: date });
                              }
                            }}
                            tabIndex="-1"
                          >
                            {date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' })}
                          </p>
                        </div>
                      );
                    })}
                  <div className="text-start mt-1">
                    <input
                      className='fs-1'
                      type="checkbox"
                      checked={isAllSession}
                      onChange={() => setIsAllSession(!isAllSession)}
                    /> Pass For All Season
                  </div>
                  <div className='text-center my-3'>
                    <button className='btn addticket_btn' onClick={() => handleDateSelection()} >Add Ticket</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default TicketDetail