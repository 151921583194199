import React, { useState } from 'react'
import { API_URL } from '../../config';
import axios from 'axios';
import apiService from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import { MdOutlineDownloadForOffline } from 'react-icons/md';

const Agreement = ({ vendorBasicDetail, vendorDocument }) => {
    const navigate = useNavigate();
    const [agreement, setAgreement] = useState();
    const [uploaded_Agreement, setUploaded_Agreement] = useState("");
    const [isBtnDisabled, setIsBtnDisabled] = useState(true);
    const [isUploaded, setIsUploaded] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFileSelection = (event) => {
        setUploaded_Agreement(event.target.files[0].name);
        setAgreement(event.target.files[0]);
        setIsBtnDisabled(false); // Enable the PAN card upload button
    };

    const handleFileUpload = async () => {
        const fd = new FormData();
        fd.append('image', agreement);

        try {
            const response = await axios.post(`${API_URL}/vendor/document/uploadagreement`, fd, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                const uploadedFilename = response.data.file;
                setAgreement(uploadedFilename);
                setIsUploaded(true); // Mark PAN card as uploaded
                setIsBtnDisabled(true); // Disable the PAN card upload button
                alert('Agreement uploaded successfully');
            }
        } catch (error) {
            alert('Error uploading PAN card');
        }
    };

    const validate = () => {
        const newErrors = {};

        if (!agreement) {
            newErrors.agreement = 'Signed Agreement is required';
        } else if (!isUploaded) {
            newErrors.uploadagreement = 'Please upload the Signed Agreement';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmitForm = async (e) => {
        e.preventDefault();
        const isValid = validate();

        if (isValid) {
            try {
                const formData = {
                    vendorBasicDetail: vendorBasicDetail,
                    vendorDocumen: vendorDocument,
                    agreement: agreement
                }
                if (formData) {
                    const response = await axios.post(`${API_URL}/vendor/addvendor`, formData)
                    if (response.status === 200) {
                        const data = {
                            _id: formData.vendorBasicDetail._id,
                            userName: formData.vendorBasicDetail.userName,
                            vendorType: formData.vendorBasicDetail.vendorType,
                            name: formData.vendorBasicDetail.name,
                            email: formData.vendorBasicDetail.email,
                            phoneNumber: formData.vendorBasicDetail.phoneNumber,
                        }
                        apiService.setData(data);
                        alert("Welcome to my front seat family!")
                        navigate("/vendor/dashboard");
                    } else {
                        alert("Error! please try again")
                    }
                }
            } catch (error) {
                alert("Something went wrong please try again")
            }
        }

    }

    // const handleDownloadPDF = async () => {
    //     const body = {
    //         Name_of_Organiser : vendorBasicDetail.name,
    //         address : vendorBasicDetail.address,
    //         email : vendorBasicDetail.email,
    //     }
    //     try {
    //         await axios.post(`${API_URL}/vendor/downloadpdf/agreement`, body)
    //             .then(response => {
    //                 if (response.status === 200) {
    //                     console.log("Downloaded")
    //                 }
    //             }).catch(err => {
    //                 console.log(err)
    //             })
    //     } catch (error) {
    //         alert("Unable to download agreement.")
    //     }
    // }

    const handleDownloadPDF = async () => {
        const body = {
            Name_of_Organiser: vendorBasicDetail.name,
            address: vendorBasicDetail.address,
            email: vendorBasicDetail.email,
            gst : vendorBasicDetail.GSTIN_Details[0].GSTIN_No || '',
            pan : vendorBasicDetail.pan_no,
            bankname : vendorBasicDetail.bank_name,
            acc_type : vendorBasicDetail.bank_Account_type,
            acc_no : vendorBasicDetail.bank_account_no,
            holder_name : vendorBasicDetail.beneficiar_name,
            ifsc : vendorBasicDetail.IFSC_code,
        };

        try {
            const response = await axios.post(
                `${API_URL}/vendor/downloadpdf/agreement`,
                body,
                { responseType: 'blob' } // Ensure the response is treated as binary data
            );

            if (response.status === 200) {
                // Create a blob from the response data
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Create a temporary URL for the blob
                const url = window.URL.createObjectURL(blob);

                // Create a hidden link element
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'Agreement.pdf'); // Desired file name
                document.body.appendChild(link);

                // Trigger the download
                link.click();

                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);

                console.log("Downloaded");
            }
        } catch (error) {
            console.error("Error downloading PDF:", error);
            alert("Unable to download agreement.");
        }
    };


    return (
        <>
            <form onSubmit={handleSubmitForm}>
                <div id="basics-detail">
                    <div className='addeventdetailDiv'>
                        <div className='container'>
                            <div className='container'>
                                <div className='mt-lg-5 mt-md-5'>
                                    <div className='text-center'>
                                        <span className='poppins30 fw-semibold'>Sign Agreement</span>
                                        <div className='mt-2'>
                                            <p onClick={handleDownloadPDF}>
                                                Download Agreement <MdOutlineDownloadForOffline className='ms-2' size={30} />
                                            </p>
                                        </div>
                                    </div>
                                    <div className='container'>
                                        <div className='container d-flex justify-content-center'>
                                            <div>
                                                <div className='mt-3'>
                                                    <div className="text-center mb-3">
                                                        <label className="poppins20 fw-semibold ms-1 mb-2 mt-3 text-center" htmlFor='agreement'>Upload Signed Agreement</label>
                                                        <div className='coverImg_style m-auto mb-3'>
                                                            <div className='uploadFile text-center'>
                                                                <AiOutlineCloudUpload className='uploadIcon' />
                                                                {agreement ? (
                                                                    <div>
                                                                        <span className='poppins16 fw-bold'>Uploaded: </span> <p>{uploaded_Agreement}</p>
                                                                    </div>
                                                                ) : (
                                                                    <div className='text-center'>
                                                                        <p className='poppins16'>Drag and Drop files or <a href="#">Browse</a></p>
                                                                        <p className='poppins12'>Supported format: jpeg, pdf</p>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <input type="file" className='selectFileStyle w-100 h-100' onChange={handleFileSelection} />
                                                        </div>
                                                        {errors.agreement && <p className="text-danger">Signed Agreement is required</p>}
                                                        {errors.uploadagreement && <p className="text-danger">Please upload the selected Signed Agreement</p>}
                                                        <button type='button' className="btn uploadbtn" onClick={handleFileUpload} disabled={isBtnDisabled}>Upload Signed Agreement</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-end'><span>We are here to help sales@myfrontseat.in</span></div>
                <div className='adminfooterDiv'>
                    <button type='submit' className="btn btn-primary basicnextbtn">Save</button>
                </div>
            </form>
        </>
    )
}

export default Agreement
