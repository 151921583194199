import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { API_URL } from '../../../config';
import { Edit2, EyeOff, Trash } from 'react-feather';
import { MdCurrencyRupee } from 'react-icons/md';
import SyncLoader from 'react-spinners/SyncLoader';
import { BsFillInfoCircleFill } from 'react-icons/bs';

function EditTicketDetail({ onNext, eventDetail, EditTicketDetails }) {
    const [isListShow, setIsListShow] = useState(true);
    const [loading, setLoading] = useState(false);
    const [newTicket, setNewTicket] = useState(false);
    const [ticket, setTicket] = useState({
        _id: "",
        ticket_Name: '',
        ticket_type: 'paid',
        total_Quantity: 0,
        addmoreTicket: 0,
        ticket_price: 0,
        sale_start: '',
        sale_start_Time: '',
        sale_end: '',
        sale_end_Time: '',
        min_booking : 0,
        max_booking : 0,
        ticket_description: '',
        isGst: "No",
        GST: 0,
        event_id: eventDetail._id,
        per_day: false,
        cover_price: 0,
        isTicket_for_date: false,
        ticket_for: '',
    });
    const [ticketList, setTicketList] = useState([]);
    // Form Validation 
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(false);
    const validate = () => {
        const newErrors = {};
        if (!ticket.ticket_Name) {
            newErrors.ticket_Name = 'Ticket name is required';
        }
        if (newTicket && !ticket.total_Quantity) {
            newErrors.total_Quantity = 'Quantity is required';
        }
        setErrors(newErrors);
        const isValidForm = Object.keys(newErrors).length === 0;
        setIsValid(isValidForm);
        return isValidForm;
    };

    const handleUpdateChanges = async (event) => {
        event.preventDefault();
        setLoading(true);
        if (!validate()) {
            return; // Prevent form submission if validation fails
        }

        try {
            const result = await axios.put(`${API_URL}/ticket/Updateticket/${ticket._id}`, ticket);
            if (result.status === 200) {
                // const data = { ticket_id: result.data.ticketCreated._id, event_id: ticket.event_id }
                // await axios.put(`${API_URL}/api/addeventticket`, data);
                getAllTickets();
                setIsListShow(true);
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            alert("Unable to add ticket");
        } finally {
            setLoading(false);
        }
    };

    const handleCreateTicket = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validate()) {
            setLoading(false);
            return; // Prevent form submission if validation fails
        }

        try {
            const result = await axios.post(`${API_URL}/ticket/createticket`, ticket);

            if (result.status === 200) {
                const data = { ticket_id: result.data.ticketCreated._id, event_id: ticket.event_id }
                await axios.put(`${API_URL}/api/addeventticket`, data);
                getAllTickets();
                setIsListShow(true);
            } else {
                alert("Something went wrong");
            }
        } catch (error) {
            alert("Unable to add ticket");
        } finally {
            setLoading(false)
        }
    }

    const getAllTickets = async () => {
        const ticketStore = await axios.get(`${API_URL}/ticket/gettickets/${ticket.event_id}`);
        setTicketList(ticketStore.data.all_tickets);
    }

    // Delete ticket functionality
    const handleDeleteTicket = async (ticket_id) => {
        await axios.delete(`${API_URL}/ticket/tickets/${ticket_id}`)
            .then((response) => {
                getAllTickets();
            }).catch((err) => alert("Unable to delete ticket"));

    };
    const handleChange = (tab) => {
        onNext(tab, isValid);
    }
    const handleEditTicket = async (tickets_id) => {
        const getdata_response = await axios.get(`${API_URL}/ticket/get-ticket/${tickets_id}`)
        if (getdata_response.status === 200 && getdata_response.data) {
            setTicket({
                _id: getdata_response.data.ticket._id,
                ticket_Name: getdata_response.data.ticket.ticket_Name,
                ticket_type: getdata_response.data.ticket.ticket_type,
                total_Quantity: getdata_response.data.ticket.total_Quantity,
                ticket_price: getdata_response.data.ticket.ticket_price,
                sale_start: getdata_response.data.ticket.sale_start,
                sale_start_Time: getdata_response.data.ticket.sale_start_Time,
                sale_end: getdata_response.data.ticket.sale_end,
                sale_end_Time: getdata_response.data.ticket.sale_end_Time,
                min_booking: getdata_response.data.ticket.min_booking,
                max_booking: getdata_response.data.ticket.max_booking,
                ticket_description: getdata_response.data.ticket.ticket_description,
                isGst: getdata_response.data.ticket.isGst,
                GST: getdata_response.data.ticket.GST,
                event_id: eventDetail._id,
                cover_price: getdata_response.data.cover_price,
                isTicket_for_date: getdata_response.data.isTicket_for_date,
                ticket_for: getdata_response.data.ticket_for_Date,

            });
            setIsListShow(false);
        }
    }
    useEffect(() => {
        if (EditTicketDetails) {
            getAllTickets();
        }
    }, [])

    return (
        <div>
            {/* Ticket Details Form */}
            <div className={`${!isListShow ? "d-block" : "d-none"}`}>
                <form>
                    <div className='addeventdetailDiv setbottommargin'>
                        <div className="container">
                            <div className='container'>
                                <div className='row' id="ticket-detail">
                                    {/* ticket name */}
                                    <div className='col-lg-6'>
                                        <div className="text-start mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_Name'>Ticket Name</label> <span className='text-danger fs-4'>*</span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='text'
                                                name='ticket_Name'
                                                placeholder='Early Bird/RSVP/Regular Entry etc'
                                                value={ticket.ticket_Name}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        ticket_Name: e.target.value
                                                    });
                                                }}
                                                required
                                            />
                                            {errors.ticket_Name && <p className='poppins16 text-danger ms-3'>{errors.ticket_Name}</p>}
                                        </div>
                                    </div>
                                    {/* ticket type */}
                                    <div className='col-lg-6'>
                                        <div className="text-start mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_type'>Ticket Type</label> <span className='text-danger fs-4'> </span>
                                            <div className='d-flex justify-content-between'>
                                                <button type='button'
                                                    className={`ticket-type-btn ${ticket.ticket_type === "paid" ? "primary text-white" : "ticket-type-btn"}`}
                                                    onClick={() => { setTicket({ ...ticket, ticket_type: "paid" }) }} >
                                                    Paid
                                                </button>
                                                <button type='button'
                                                    className={`ticket-type-btn ${ticket.ticket_type === "free" ? "primary text-white" : "ticket-type-btn"}`}
                                                    onClick={() => { setTicket({ ...ticket, ticket_type: "free" }) }} >
                                                    Free
                                                </button>
                                                {/* <button type='button' className={`"btn tickettypebtn border-primary" ${ticket.ticket_type === "private" ? "btn primary text-white" : "btn border-primary"}`} onClick={() => { setTicket({ ...ticket, ticket_type: "private" }) }}> Private</button> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* ticket description */}
                                    <div className='col-lg-6'>
                                        <div className="text-start mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_description'>Ticket Description</label> <span className='text-danger fs-4'></span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='text'
                                                name='ticket_description'
                                                placeholder='Cover/No-cover/Entire Only etc'
                                                value={ticket.ticket_description}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        ticket_description: e.target.value
                                                    });
                                                }} />
                                        </div>
                                    </div>
                                    {/* ticket price */}
                                    <div className={`col-lg-6 ${ticket.ticket_type === "paid" || ticket.ticket_type === "private" ? "d-block" : "d-none"}`}>
                                        <div className={`text-start mb-3`}>
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_price'>Ticket Price</label> <span className='text-danger fs-4'>*</span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='ticket_price'
                                                placeholder='Enter Ticket Price in INR'
                                                value={ticket.ticket_price === 0 ? '' : ticket.ticket_price}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        ticket_price: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* ticket quantity */}
                                    <div className='col-lg-6'>
                                        <div className="text-start mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='total_Quantity'>Total Quantity</label> <span className='text-danger fs-4'>*</span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='total_Quantity'
                                                placeholder='Enter Total Quantity'
                                                value={ticket.total_Quantity ===0 ? "" : ticket.total_Quantity}
                                                disabled={!newTicket}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        total_Quantity: e.target.value,
                                                    });
                                                }}
                                            />
                                            {errors.total_Quantity && <p className='text-danger ms-3'>{errors.total_Quantity}</p>}
                                        </div>
                                    </div>
                                    <div className={`col-lg-6  ${newTicket ? 'd-none' : 'd-block'}`}>
                                        <div className="text-start mb-3">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='addmoreTicket'>Additional Ticket Quantity</label> <span className='text-danger fs-4'>*</span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='addmoreTicket'
                                                placeholder='Enter additional ticket Quantities'
                                                value={ticket.addmoreTicket ==0 ? '' : ticket.addmoreTicket}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        addmoreTicket: e.target.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* cover price */}
                                    <div className={`col-lg-6 ${ticket.ticket_type === "paid" || ticket.ticket_type === "private" ? "d-block" : "d-none"}`}>
                                        <div className="mb-3 text-start">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='max_booking'>Cover Price</label> <span className='text-danger fs-4'> </span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='cover_price'
                                                value={ticket.cover_price === 0 ? '' : ticket.cover_price}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        cover_price: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* sale start from */}
                                    <div className='col-lg-6'>
                                        <div className="mb-3 text-start">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_start'>Ticket sale Starts from</label> <span className='text-danger fs-4'> </span>
                                            <div className='d-flex px-1'>
                                                <input className='form-control me-3' style={{ borderColor: "#2C9CF0" }}
                                                    type='date'
                                                    name='sale_start'
                                                    value={ticket.sale_start}
                                                    min={new Date().toISOString().split('T')[0]}
                                                    onChange={(e) => {
                                                        setTicket({
                                                            ...ticket,
                                                            sale_start: e.target.value
                                                        });
                                                    }}
                                                />
                                                <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                    type='time'
                                                    name='sale_start_Time'
                                                    value={ticket.sale_start_Time}
                                                    onChange={(e) => {
                                                        setTicket({
                                                            ...ticket,
                                                            sale_start_Time: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* sale end at */}
                                    <div className='col-lg-6'>
                                        <div className="mb-3 text-start">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='sale_end'>Ticket Sale Ends at</label>
                                            <div className='d-flex px-1 mb-2'>
                                                <input className='form-control me-3' style={{ borderColor: "#2C9CF0" }}
                                                    type='date'
                                                    name='sale_end'
                                                    value={ticket.sale_end}
                                                    min={ticket.sale_start || new Date().toISOString().split('T')[0]}
                                                    onChange={(e) => {
                                                        setTicket({
                                                            ...ticket,
                                                            sale_end: e.target.value
                                                        });
                                                    }}
                                                />
                                                <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                    type='time'
                                                    name='sale_end_Time'
                                                    value={ticket.sale_end_Time}
                                                    onChange={(e) => {
                                                        setTicket({
                                                            ...ticket,
                                                            sale_end_Time: e.target.value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* min booking  */}
                                    <div className='col-lg-6'>
                                        <div className="mb-3 text-start">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='min_booking'>Minimum per Booking</label>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='min_booking'
                                                placeholder='Enter minimum quantity'
                                                value={ticket.min_booking === 0 ? '' : ticket.min_booking}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        min_booking: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {/* max booking */}
                                    <div className='col-lg-6'>
                                        <div className="mb-3 text-start">
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='max_booking'>Maximum per Booking</label>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='number'
                                                onWheel={(e) => e.target.blur()}
                                                name='max_booking'
                                                placeholder='Enter maximum quantity'
                                                value={ticket.max_booking === 0 ? "" :ticket.max_booking}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        max_booking: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* Ticket for Particular date */}
                                    <div className='col-lg-6'>
                                        <input
                                            type='checkbox'
                                            name='isTicket_for_date'
                                            checked={ticket.isTicket_for_date}  // Bind the checkbox's checked state to ticket.isTicket_for_date
                                            onChange={(e) => setTicket({ ...ticket, isTicket_for_date: e.target.checked })} // Set per_day based on checked state
                                        />
                                        <span> Ticket for particular Day</span> <span><BsFillInfoCircleFill className='text-info' /></span>
                                        <div className={`mb-3 ${!ticket.isTicket_for_date ? 'd-none' : 'd-block'}`}>
                                            <label className="poppins20 fw-semibold ms-1 mb-2" htmlFor='ticket_for'>Date for this Ticket :</label> <span className='text-danger fs-4'></span>
                                            <input className='form-control' style={{ borderColor: "#2C9CF0" }}
                                                type='date'
                                                name='ticket_for'
                                                value={ticket.ticket_for}
                                                onChange={(e) => {
                                                    setTicket({
                                                        ...ticket,
                                                        ticket_for: e.target.value
                                                    });
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='ticketfooterDiv d-flex justify-content-center py-3'>
                        {
                            !newTicket ?
                                <button type='submit' className='btn createticketbtn' onClick={handleUpdateChanges}>
                                    {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Update Ticket"}
                                </button>
                                :
                                <button type='submit' className='btn createticketbtn' onClick={handleCreateTicket}>
                                    {loading ? <SyncLoader animation="border" color="#FFFF" size="7" speedMultiplier={1} margin={4} /> : "Create Ticket"}
                                </button>
                        }
                    </div>
                </form>
            </div>
            <div className={`${isListShow ? "d-block" : "d-none"}`}>
                {/* Added Ticket List */}
                <div className={`addeventdetailDiv mt-4`} id="largesScreenDevice">
                    {
                        ticketList.map((tickets) => {
                            return (
                                <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "90%" }}
                                    key={tickets._id}>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-12 text-start d-flex flex-column' >
                                        <span className='fw-bold'>{tickets.ticket_Name}</span>
                                        <span style={{ fontSize: "small" }}>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                                    </div>
                                    <div className='col-lg-2'>
                                        <p>{tickets.total_Quantity} Ticket(s)</p>
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-3'><MdCurrencyRupee /> {tickets.ticket_price}</div>
                                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'><Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} /></div>
                                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                                        <Edit2 size={17} onClick={() => { handleEditTicket(tickets._id); setNewTicket(false) }} />
                                    </div>
                                    <div className='col-lg-1 col-md-1 col-sm-1 col-3'>
                                        {/* <EyeOff size={17} /> */}
                                    </div>
                                    <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                                </div>
                            )
                        })
                    }
                    <div className='text-center'>
                        <button
                            className="btn addticketbtn my-3 fw-bold"
                            onClick={() => { setIsListShow(false); setNewTicket(true) }}>+Add Another Ticket</button>
                    </div>
                    {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}
                </div>
                <div className={`addeventdetailDiv mt-4`} id="SmallScreenDevice">
                    {
                        ticketList.map((tickets) => {
                            return (
                                <div className='row container px-lg-2 py-2 mb-3 border-bottom border-dark m-auto' style={{ width: "99%" }}
                                    key={tickets._id}>
                                    <div className='col-6 text-start d-flex flex-column'>
                                        <span className='fw-bold poppins16-sm'>{tickets.ticket_Name}</span>
                                        <span className='poppins10-sm'>Sale Starts at {tickets.sale_start} - Ends at {tickets.sale_end}</span>
                                    </div>
                                    <div className='col-3'>
                                        <p className='poppins14-sm'>{tickets.total_Quantity} Ticket(s)</p>
                                    </div>
                                    <div className='col-3'>
                                        <p className='poppins14-sm'><MdCurrencyRupee /> {tickets.ticket_price}</p>
                                    </div>
                                    <div className='col-6'>
                                        <div className='col-lg-2 col-md-2 col-sm-2 col-12'><span className='rounded-5 ticketactivebtn m-auto'>Active</span></div>
                                    </div>
                                    <div className='col-6 d-flex justify-content-between'>
                                        <Trash size={17} onClick={() => handleDeleteTicket(tickets._id)} />
                                        <Edit2 size={17} onClick={() => { handleEditTicket(tickets._id); setNewTicket(false) }} />
                                        {/* <EyeOff size={17} /> */}
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='text-center'>
                        <button
                            className="btn addticketbtn my-3 fw-bold"
                            onClick={() => { setIsListShow(false); setNewTicket(true) }}>+Add Another Ticket</button>
                    </div>
                    {/* <div className='text-start w-75 mb-5 m-auto'>
          <p className='fw-bold'>Does your ticketing involve GST</p>
          <input type='radio' name="isGst" /> Yes
          <input type='radio' className='ms-5' name="isGst" /> No
        </div>
        <div className='col-lg-2 text-start w-75 mb-5 m-auto'>
          <select type="number" name='GST' onChange={(e) => {
                      setTicket({
                        ...ticket,
                        GST: e.target.value
                      });
                    }}>
            <option value="default" selected>18</option>
            <option value="19" selected>19</option>
            <option value="20" selected>20</option>
            <option value="10" selected>10</option>
          </select> %
        </div> */}

                </div>
                <div className='adminfooterDiv'>
                    {/* <button type='button'
            className={`btn btn-secondary me-5 px-5 `}
            onClick={()=>handleChange("add-details")}
          >Back</button> */}
                    <button type='button' className='btn basicnextbtn' onClick={() => handleChange("add-on")}>Next</button>
                </div>
            </div>
        </div>

    )
}

export default EditTicketDetail